import React, { useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Events } from '@utils/enums/events';
import { useHistory } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import ButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Modal from '@components/Modal';
import LineChart from '@components/Scores/LineChart';
import RadialChart from '@components/Scores/RadialChart';

import { checkFlag, checkFlags } from '@utils/helpers/checkPermission';
import Flags from '@utils/constants/flags';
import Utils from '@utils/efxutil';
import ICreditMonitoring from '@interfaces/creditMonitoring';
import NoData from './NoData';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Download from '@assets/icons/download';
import { Route } from '@utils/enums/routes';

interface IScoreProp {
  scoreChange: number;
}

interface IProps {
  isAIO?: boolean;
  showReports?: boolean;
  data: ICreditMonitoring;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: '10px 0'
    },
    boxTitle: {
      margin: 0,
      marginBottom: 20,
      fontSize: fontSizes.h3
    },
    titles: {
      fontWeight: 600,
      fontSize: fontSizes.paragraph2
    },
    dates: {
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    description: {
      paddingRight: 5,
      fontSize: fontSizes.paragraph2
    },
    score: {
      paddingLeft: 5,
      fontWeight: 600,
      textAlign: 'right',
      fontSize: fontSizes.paragraph2
    },
    score1: {
      marginLeft: 5,
      fontWeight: 'bold',
      fontSize: fontSizes.h1,
      '& > span': {
        paddingRight: 10,
        color: colors.lightInputText,
        fontSize: fontSizes.paragraph1
      }
    },
    scoreSince: {
      marginLeft: 10,
      color: colors.lightInputText,
      '& > span:last-of-type': {
        marginLeft: 5,
        fontWeight: 'bold',
        color: colors.lightBlack
      }
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div': {
          margin: 0,
          marginBottom: 10
        }
      }
    },
    lineChart: {
      [theme.breakpoints.down('sm')]: {
        height: 160,
        overflowY: 'hidden'
      }
    },
    reportsWrapper: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& > div': {
          margin: '5px !important',
          flex: '0 32% !important',
          flexDirection: 'column'
        },
        '& > div:first-of-type': {
          flex: '100% !important'
        }
      }
    },
    divider: {
      borderBottom: 'none',
      color: colors.supportive,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    link: {
      paddingBottom: 3,
      color: theme.palette.action.active
    },
    customerColor: {
      color: theme.palette.action.active
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
  @media print {
    > div {
      width: 100%;
      display: flex;
      max-width: 70%;
      margin: 0 auto;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
        > div:first-of-type: {
          order: 2;
        }
        > div:last-of-type {
          order: 1;
        }
      }
    }
  }
`;

const Card = styled.div`
  height: 100%;
  padding: 25px;
  background: white;
  border-radius: 4px;
  border: 1px solid #dadfe3;
`;

const ScoreWrapper = styled(Card)`
  flex: 5;
  display: flex;
  margin-right: 15px;
  flex-direction: column;
  padding-bottom: 35px;
  border: 1px solid #dadfe3;
    > div:first-of-type {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  
    > div.gauge {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    > div.bottom,
    > div.bottom-responsive {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
  
        &:first-of-type {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${colors.gray};
        }
      }
    }
      
    .bottom-responsive {
      display: none;
      div {
        font-size: ${fontSizes.paragraph2} !important;
        &:not(:last-of-type) {
          margin-bottom: 10px !important;
          padding-bottom: 10px !important;
          border-bottom: 1px solid ${colors.gray} !important;
        }
      }
    }
    @media (max-width: 600px) {
      .bottom-responsive {
        display: block;
      }
      .bottom {
        display: none;
      }
    }
  }
`;

const DetailsWrapper = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  > div:not(:first-of-type) {
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    div:first-of-type {
      background: transparent;
      p.score {
        padding: 10px;
        margin: -5px;
        background: white;
        border-radius: 4px;
      }
    }
  }
`;

const ReportsWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  > div {
    flex: 1;
    padding: 25px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    background-color: white;
    border: 1px solid ${colors.lightInputText};
    h1 {
      margin: 0;
      font-size: ${fontSizes.h2};
      color: ${colors.lightBlack};
    }
    span {
      color: ${colors.supportive};
      font-size: ${fontSizes.paragraph2};
    }
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }
  @media print {
    display: flex;
    flex-direction: row;
    > div {
      flex: 1 !important;
      flex-direction: column;
      &:first-of-type {
        flex: 1 !important;
      }
    }
  }
`;

const DownloadText = styled.span`
  letter-spacing: 0.02em;
  font-size: ${fontSizes.h4};
  line-height: 155%;
`;

const DownloadIcon = styled.span`
  margin-right: 8px;
`;

const IconWrapper = styled.span<IScoreProp>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ scoreChange }) =>
    scoreChange > 0
      ? `${colors.lightGreen}40`
      : scoreChange < 0
      ? `${colors.lightPink}40`
      : `${colors.lightCyan}40`};
  }};
  svg {
    transform: rotate(${({ scoreChange }) =>
      scoreChange > 0 ? '-45deg' : '45deg'});
  }
  span {
    display: block;
    font-size: 35px;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    color: ${colors.lightCyan};
  }
`;

const ScoreDifference = styled.span<IScoreProp>`
  margin-right: 5px;
  color: ${({ scoreChange }) =>
    scoreChange > 0
      ? `${colors.lightGreen}`
      : scoreChange < 0
      ? `${colors.lightPink}`
      : `${colors.lightCyan}`};
  }};
`;

const Score: React.FC<IProps> = ({ data, showReports, isAIO }) => {
  const { push } = useHistory();
  const {
    appStore,
    dashboardStore,
    userStore: { user }
  } = useStores();
  const classes = useStyles();
  const provider = data.provider;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const printPdf = () => {
    window.window.parent.postMessage(
      { type: Events.DOWNLOAD_PDF_CREDIT_REPORT_LINK_TRIGGERED },
      '*'
    );
    window.print();
  };

  const sc = Utils._processScores(dashboardStore.latestScore);
  const sh = Utils._processScoreHistory(dashboardStore.scoreHistory);
  const rl = Utils._processSummary(dashboardStore.reportList);

  const latestScore = sc && sc[provider];
  const scoreHistory = sh && sh[provider];
  const reportList = rl && rl[provider];

  const scoreChange = (): number => {
    if (latestScore && scoreHistory) {
      return sc[provider].score - sh[provider].lastScore;
    }

    return 0;
  };

  return (
    <div className={classes.root}>
      <Wrapper className={classes.wrapper}>
        <ScoreWrapper>
          <div>
            <h2 className={classes.boxTitle}>Score</h2>
            <ButtonBase onClick={printPdf} className={'hidePrint'}>
              <DownloadIcon>
                <Download />
              </DownloadIcon>
              <DownloadText className={classes.customerColor}>
                Download
              </DownloadText>
            </ButtonBase>
          </div>
          <div className={'gauge'}>
            <RadialChart data={latestScore} />
          </div>
          <div className="bottom">
            <div className={classes.titles}>
              <span>Updated</span>
              <span>Next update</span>
            </div>
            <div className={classes.dates}>
              <span>{moment(latestScore.date).format('MMM DD, Y')}</span>
              {checkFlag(appStore.getConfig().flags, Flags.WEEKLY_UPDATE) ? (
                <span>
                  {moment(latestScore.date)
                    .add(7, 'd')
                    .format('MMM DD, Y')}
                </span>
              ) : (
                <span>
                  {moment(latestScore.date)
                    .add(1, 'M')
                    .format('MMM DD, Y')}
                </span>
              )}
            </div>
            <div>
              <span className={classes.description}>
                Credit scores used by vendors may vary.
              </span>
              <span className={classes.score}>
                {'VantageScore\u00AE 3.0 credit score using Equifax data.'}
              </span>
            </div>
          </div>
          <div className="bottom-responsive">
            <div className={classes.titles}>
              <span>Updated</span>
              <span>{moment(latestScore.date).format('MMM DD, Y')}</span>
            </div>
            <div className={classes.titles}>
              <span>Next update</span>
              {checkFlag(appStore.getConfig().flags, Flags.WEEKLY_UPDATE) ? (
                <span>
                  {moment(latestScore.date)
                    .add(7, 'd')
                    .format('MMM DD, Y')}
                </span>
              ) : (
                <span>
                  {moment(latestScore.date)
                    .add(1, 'M')
                    .format('MMM DD, Y')}
                </span>
              )}
            </div>
            <div>
              <span>
                {'VantageScore\u00AE 3.0 credit score using Equifax data.'}
              </span>
            </div>
            <div>
              <span>Credit scores used by vendors may vary.</span>
            </div>
          </div>
        </ScoreWrapper>
        <DetailsWrapper>
          {(checkFlag(appStore.getConfig().flags, Flags.SCORE_FACTORS) ||
            checkFlag(appStore.getConfig().flags, Flags.SCORE_CHANGE)) && (
            <Card>
              <div>
                <h2 className={classes.boxTitle}>Points difference</h2>
              </div>
              <div>
                <hr className={classes.divider} />
              </div>
              <div>
                <p style={{ fontSize: fontSizes.paragraph2 }}>
                  <b>Hi, {user && user.fname}</b>
                </p>
                {checkFlag(appStore.getConfig().flags, Flags.SCORE_FACTORS) && (
                  <p style={{ fontSize: fontSizes.paragraph2 }}>
                    View{' '}
                    <ButtonBase
                      className={classes.link}
                      onClick={() => {
                        window.window.parent.postMessage(
                          { type: Events.SCORE_FACTORS_LINK_TRIGGERED },
                          '*'
                        );
                        if (!isAIO) {
                          setIsModalOpen(!isModalOpen);
                        } else {
                          // @ts-ignore
                          document.getElementById('factors').scrollIntoView();
                        }
                      }}
                    >
                      Score Factors
                    </ButtonBase>{' '}
                    to see what affects your Credit Score.
                  </p>
                )}
                {checkFlag(appStore.getConfig().flags, Flags.SCORE_CHANGE) && (
                  <p className="score">
                    {scoreChange() !== 0 && (
                      <>
                        <IconWrapper scoreChange={scoreChange()}>
                          {scoreChange() !== 0 && <ArrowForwardIcon />}
                          {/*{scoreChange() === 0 && <span>-</span>}*/}
                        </IconWrapper>
                      </>
                    )}
                    <span className={classes.score1}>
                      {latestScore.score} <span>points</span>
                    </span>
                    {' | '}
                    <span className={classes.scoreSince}>
                      {scoreChange() !== 0 && (
                        <>
                          <ScoreDifference scoreChange={scoreChange()}>
                            {latestScore.score - scoreHistory.lastScore}
                          </ScoreDifference>
                          since
                        </>
                      )}
                      <span>
                        {latestScore.score && latestScore.date
                          ? moment(latestScore.date).format('MMM DD, Y')
                          : 'N/A'}
                      </span>
                    </span>
                  </p>
                )}
              </div>
            </Card>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.SCORE_HISTORY) &&
          scoreHistory ? (
            <Card>
              <div>
                <h2 className={classes.boxTitle}>Score Trend</h2>
              </div>
              <div className={classes.lineChart}>
                <LineChart data={scoreHistory.scores.slice().reverse()} />
              </div>
            </Card>
          ) : (
            <Card style={{ display: 'flex' }}>
              <NoData text="No score trend data to display." />
            </Card>
          )}
        </DetailsWrapper>
      </Wrapper>
      {checkFlags(appStore.getConfig().flags, [
        Flags.SCORE_DETAILS,
        Flags.REPORT
      ]) &&
        rl &&
        showReports && (
          <ReportsWrapper className={classes.reportsWrapper}>
            <div
              onClick={() => {
                push(`${Route.DASHBOARD_REPORT}/#s3`);
              }}
            >
              <h1>
                ${reportList.accountsDetail['Total'].avail.toLocaleString()}
              </h1>
              <span>available credit</span>
            </div>
            <div
              onClick={() => {
                push(`${Route.DASHBOARD_REPORT}/#s1`);
              }}
            >
              <h1>
                {Math.trunc(reportList.additional.avgAccountAge / 12)} years
              </h1>
              <span>oldest credit line</span>
            </div>
            <div
              onClick={() => {
                push(`${Route.DASHBOARD_REPORT}/#s4`);
              }}
            >
              <h1>{reportList.accountsDetail['Total'].debtRatio}%</h1>
              <span>credit used</span>
            </div>
            <div
              onClick={() => {
                push(`${Route.DASHBOARD_REPORT}/#s3`);
              }}
            >
              <h1>{reportList.additional.inquiries}</h1>
              <span>recent inquiries</span>
            </div>
            <div
              onClick={() => {
                push(`${Route.DASHBOARD_REPORT}/#s4`);
              }}
            >
              <h1>{reportList.accountsDetail['Total'].open}</h1>
              <span>open accounts</span>
            </div>
          </ReportsWrapper>
        )}
      <Modal closeModal={setIsModalOpen} isOpen={isModalOpen}>
        {latestScore.factors.positive.length > 0 && (
          <div>
            <p>Positive Factors:</p>
            <ul>
              {latestScore.factors.positive.map((f: any, i: number) => {
                return <li key={i}>{f.description}</li>;
              })}
            </ul>
            <hr />
          </div>
        )}
        {latestScore.factors.negative.length > 0 && (
          <div>
            <p>Negative Factors:</p>
            <ul>
              {latestScore.factors.negative.map((f: any, i: number) => {
                return <li key={i}>{f.description}</li>;
              })}
            </ul>
          </div>
        )}
      </Modal>
    </div>
  );
};

Score.defaultProps = {
  showReports: true,
  isAIO: false
};

export default observer(Score);
