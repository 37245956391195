import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import ButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import RadialChart from '@components/Scores/RadialChart';

import Utils from '@utils/efxutil';
import { IScoreUpStats, IScoreUpPathway } from '@interfaces/scoreUp';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

interface IProps {
  isAIO?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: '10px 0'
    },
    boxTitle: {
      margin: '0 auto',
      marginBottom: 25,
      fontSize: fontSizes.h3
    },
    subBox: {
      marginTop: 10,
      marginBottom: 5,
      background: '#f8f8f8',
      border: '1px solid #df0909',
      padding: 2,
      '& > span': {
        fontWeight: 900
      },
      '& > div': {
        marginTop: 5,
        marginLeft: 5
      }
    },
    titles: {
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    score_range_title: {
      marginTop: 15,
      paddingTop: 5,
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1,
      borderTop: '1px solid black'
    },
    score_range: {
      paddingLeft: 5,
      fontWeight: 600,
      textAlign: 'right',
      fontSize: fontSizes.h1
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div': {
          margin: 0,
          marginBottom: 10
        }
      }
    },
    incButton: {
      background: 'green',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px',
      marginRight: 5,
      marginLeft: 5
    },
    timeButton: {
      background: '#bbb',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px'
    },
    disabledButton: {
      background: '#666',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px'
    }
  })
);

const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
  @media print {
    > div {
      width: 100%;
      display: flex;
      max-width: 70%;
      margin: 0 auto;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
        > div:first-of-type: {
          order: 2;
        }
        > div:last-of-type {
          order: 1;
        }
      }
    }
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid #dadfe3;
  box-shadow: 5px 5px 5px gray;
  margin: 0px 5px 0px 0px;
  padding: 0px 2px 0px 2px;
`;

const ScoreWrapper = styled(Card)`
  box-shadow: none;
  border: 0px;
  flex: 5;
  display: flex;
  margin-right: 15px;
  padding-top: 0px;
  flex-direction: column;

    > div:first-of-type {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  
    > div.gauge {
      display: flex;
      align-items: start;
      justify-content: center;
    }
  
    > div.bottom {
      div {
        display: flex;
        align-items: start;
        justify-content: space-between;
  
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
  
        &:first-of-type {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${colors.gray};
        }
      }
    }

    > div.options {
      display: flex;
      flex-wrap: wrap;
      align-items: start;
      justify-content: center;
      margin-top: 20px;

      > div {
        display: flex;
        color: green;
        border: 1px solid #000;
        margin: 5px;
        padding: 5px;
        flex: 1 0 15%;
        align-items: stretch;
        justify-content: center;
        text-align: center;
      }
    }
  }
`;

const DetailsWrapper = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  > div:not(:first-of-type) {
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    div:first-of-type {
      background: transparent;
      p.score {
        padding: 10px;
        margin: -5px;
        background: white;
        border-radius: 4px;
      }
    }
  }
`;

const ScoreUp: React.FC<IProps> = ({ isAIO }) => {
  const { dashboardStore } = useStores();
  const classes = useStyles();
  const [scoreUp, setScoreUp] = useState<IScoreUpPathway>();
  const [scoreUpStats, setScoreUpStats] = useState<IScoreUpStats>();
  const [statsLoaded, setStatsLoaded] = useState<boolean>(false);
  const [scoreChange, setScoreChange] = useState<number>(10);
  const [timeChange, setTimeChange] = useState<number>(6);

  const sc = Utils._processScores(dashboardStore.latestScore);

  let reportDataChange =
    dashboardStore.reportLoaded && dashboardStore.report && !statsLoaded;
  useEffect(() => {
    if (dashboardStore.reportLoaded) {
      let stats: IScoreUpStats = {};
      let rep = dashboardStore?.report?.providerViews.filter(
        (f: any) => f.provider === 'EFX'
      )[0];
      let nh = Utils._getAllNegativePaymentHistory(rep);
      //.reduce((prev: any, curr: any) =>
      //  prev.date < curr.date ? prev : curr
      //)
      stats.latePaymentsCount = nh.length;
      stats.mostRecentLatePayment = nh.reduce(
        (prev: any, curr: any) => (prev?.date > curr?.date ? prev : curr),
        null
      );
      stats.collectionsCount = rep?.collections.length;
      stats.creditUtilization =
        rep?.summary?.totalOpenAccounts?.debtToCreditRatio || 0;
      //stats.creditCardUtilization = rep?
      stats.revolvingUtilization =
        rep?.summary?.revolvingAccounts?.debtToCreditRatio || 0;
      stats.totalOpenBalance =
        rep?.summary?.totalOpenAccounts?.balance?.amount || 0;
      stats.revolvingBalance =
        rep?.summary?.revolvingAccounts?.balance?.amount || 0;
      stats.openAccounts = rep?.summary?.totalOpenAccounts?.totalAccounts || 0;
      //stats.newAccounts
      let date2yr = Date.now() - 63071999999;
      stats.newAccounts =
        rep?.revolvingAccounts?.reduce(
          (count: number, rec: any) =>
            rec.dateOpened > date2yr ? count + 1 : count,
          0
        ) +
        rep?.mortgageAccounts?.reduce(
          (count: number, rec: any) =>
            rec.dateOpened > date2yr ? count + 1 : count,
          0
        ) +
        rep?.installmentAccounts?.reduce(
          (count: number, rec: any) =>
            rec.dateOpened > date2yr ? count + 1 : count,
          0
        ) +
        rep?.otherAccounts?.reduce(
          (count: number, rec: any) =>
            rec.dateOpened > date2yr ? count + 1 : count,
          0
        );
      setScoreUpStats(stats);
      setStatsLoaded(true);
    }
  }, [reportDataChange, dashboardStore]);

  let scoreUpDataChange =
    dashboardStore.scoreUpLoaded && dashboardStore.scoreUp;
  useEffect(() => {
    if (!scoreUpDataChange) return;
    let val: IScoreUpPathway | any =
      scoreChange >=
        dashboardStore.scoreUp.directPathway.scoreIncrementLowerBound &&
      scoreChange <=
        dashboardStore.scoreUp.directPathway.scoreIncrementUpperBound &&
      timeChange === dashboardStore.scoreUp.directPathway.timeHorizon
        ? dashboardStore?.scoreUp?.directPathway
        : null;
    setScoreUp(
      val
        ? val
        : dashboardStore?.scoreUp?.possiblePathways?.filter(
            (pw: any) =>
              scoreChange >= pw.scoreIncrementLowerBound &&
              scoreChange <= pw.scoreIncrementUpperBound &&
              timeChange === pw.timeHorizon
          )[0]
    );
  }, [scoreUpDataChange, timeChange, scoreChange, dashboardStore]);

  const latestScore = sc && sc['EFX'];
  const score = latestScore.score;

  const isScoreDisabled = (val: number) => {
    return scoreChange === val;
  };

  const isTimeDisabled = (val: number) => {
    return timeChange === val;
  };

  return (
    <div className={classes.root}>
      <Wrapper
        className={classes.wrapper}
        style={{ flexDirection: isAIO ? 'column' : undefined }}
      >
        <ScoreWrapper>
          <div style={{ display: isAIO ? 'none' : undefined }}>
            <h2 className={classes.boxTitle}>
              Equifax VantageScore&reg; 3.0 Credit Score
            </h2>
          </div>
          <div
            className={'gauge'}
            style={{ display: isAIO ? 'none' : undefined }}
          >
            <RadialChart data={latestScore} />
          </div>
          <div className="bottom">
            <div className={classes.titles}>
              <span style={{ fontWeight: 'bold', margin: '0 auto' }}>
                Set Your Target Score
              </span>
            </div>
            <div>
              <ButtonBase
                onClick={() => setScoreChange(10)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(10)}
              >
                {score + 10}
              </ButtonBase>
              <ButtonBase
                onClick={() => setScoreChange(20)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(20)}
              >
                {score + 20}
              </ButtonBase>
              <ButtonBase
                onClick={() => setScoreChange(40)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(40)}
              >
                {score + 40}
              </ButtonBase>
              <ButtonBase
                onClick={() => setScoreChange(60)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(60)}
              >
                {score + 60}
              </ButtonBase>
              <ButtonBase
                onClick={() => setScoreChange(80)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(80)}
              >
                {score + 80}
              </ButtonBase>
              <ButtonBase
                onClick={() => setScoreChange(100)}
                classes={{
                  root: classes.incButton,
                  disabled: classes.disabledButton
                }}
                disabled={isScoreDisabled(100)}
              >
                {score + 100}
              </ButtonBase>
            </div>
            <div className={classes.titles}>
              <span style={{ fontWeight: 'bold', margin: '0 auto' }}>
                Set Your Time Horizon
              </span>
            </div>
            <div>
              <ButtonBase
                onClick={() => setTimeChange(6)}
                classes={{
                  root: classes.timeButton,
                  disabled: classes.disabledButton
                }}
                disabled={isTimeDisabled(6)}
              >
                6mo
              </ButtonBase>
              <ButtonBase
                onClick={() => setTimeChange(12)}
                classes={{
                  root: classes.timeButton,
                  disabled: classes.disabledButton
                }}
                disabled={isTimeDisabled(12)}
              >
                12mo
              </ButtonBase>
              <ButtonBase
                onClick={() => setTimeChange(18)}
                classes={{
                  root: classes.timeButton,
                  disabled: classes.disabledButton
                }}
                disabled={isTimeDisabled(18)}
              >
                18mo
              </ButtonBase>
              <ButtonBase
                onClick={() => setTimeChange(24)}
                classes={{
                  root: classes.timeButton,
                  disabled: classes.disabledButton
                }}
                disabled={isTimeDisabled(24)}
              >
                24mo
              </ButtonBase>
            </div>
            <div className={classes.score_range_title}>
              <span style={{ fontWeight: 'bold', margin: '0 auto' }}>
                Your New Score Range
              </span>
            </div>
            <div className={classes.score_range}>
              <span style={{ fontWeight: 'bold', margin: '0 auto' }}>
                {score + scoreUp?.scoreIncrementLowerBound} -&nbsp;
                {score + scoreUp?.scoreIncrementUpperBound}
              </span>
            </div>
          </div>
        </ScoreWrapper>
        <DetailsWrapper>
          <Card>
            <div>
              <h2 className={classes.boxTitle}>
                PAYMENT ACTIVITY&nbsp;
                <span style={{ fontSize: '0.5em', color: 'red' }}>
                  HIGH IMPACT
                </span>
              </h2>
            </div>
            <div>
              {scoreUp?.paymentPathway}
              <div className={classes.subBox}>
                <span>Your Payment Activity:</span>
                {!statsLoaded && <p>Loading</p>}
                {statsLoaded && (
                  <div>
                    {!!scoreUpStats?.latePaymentsCount && (
                      <div>
                        {scoreUpStats?.latePaymentsCount} Late Payment
                        {scoreUpStats?.latePaymentsCount ? 's' : ''}. Most
                        recent:
                        {moment(
                          scoreUpStats?.mostRecentLatePayment?.date
                        ).format(' MMMM Y')}
                      </div>
                    )}
                    {!scoreUpStats?.latePaymentsCount && (
                      <div>
                        No Late Payments found on your Credit Report. Great Job!
                      </div>
                    )}
                    {!!scoreUpStats?.collectionsCount && (
                      <div>
                        {scoreUpStats?.collectionsCount} Collections found on
                        your report.
                      </div>
                    )}
                    {!scoreUpStats?.collectionsCount && (
                      <div>
                        No collections found on your Credit Report. Great Job!
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
          <Card>
            <div>
              <h2 className={classes.boxTitle}>
                CREDIT UTILIZATION&nbsp;
                <span style={{ fontSize: '0.5em', color: '#d0d000' }}>
                  MEDIUM IMPACT
                </span>
              </h2>
            </div>
            <div>
              {scoreUp?.utilizationPathway}
              <div className={classes.subBox}>
                <span>Your Percentage of Credit Used:</span>
                {!statsLoaded && <p>Loading</p>}
                {statsLoaded && (
                  <div>
                    {!!scoreUpStats?.creditUtilization && (
                      <div>
                        Total Credit Utilization:&nbsp;
                        {scoreUpStats?.creditUtilization}%
                      </div>
                    )}
                    {scoreUpStats?.creditUtilization === 0 && (
                      <div>
                        Looks like you're not using any available Credit at this
                        time.
                      </div>
                    )}
                    {!!scoreUpStats?.revolvingUtilization && (
                      <div>
                        Revolving Account Utilization:&nbsp;
                        {scoreUpStats?.revolvingUtilization}%
                      </div>
                    )}
                    {scoreUpStats?.revolvingUtilization === 0 && (
                      <div>
                        Looks like you're not using any Revolving Credit at this
                        time.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
          <Card>
            <div>
              <h2 className={classes.boxTitle}>
                DEBT AND BALANCES&nbsp;
                <span style={{ fontSize: '0.5em', color: '#d0d000' }}>
                  MEDIUM IMPACT
                </span>
              </h2>
            </div>
            <div>
              {scoreUp?.debtBalancePathway}
              <div className={classes.subBox}>
                <span>Your Debt and Balances:</span>
                {!statsLoaded && <p>Loading</p>}
                {statsLoaded && (
                  <div>
                    <div>
                      Current Balances across all Open Accounts: $
                      {scoreUpStats?.totalOpenBalance?.toLocaleString()}
                    </div>
                    <div>
                      Revolving Account Balances: $
                      {scoreUpStats?.revolvingBalance?.toLocaleString()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
          <Card>
            <div>
              <h2 className={classes.boxTitle}>
                CREDIT ACTIVITY&nbsp;
                <span style={{ fontSize: '0.5em', color: 'green' }}>
                  LOW IMPACT
                </span>
              </h2>
            </div>
            <div>
              {scoreUp?.creditActivityPathway}
              <div className={classes.subBox}>
                <span>Your Recent Credit Activity:</span>
                {!statsLoaded && <p>Loading</p>}
                {statsLoaded && (
                  <div>
                    <div>Open Accounts: {scoreUpStats?.openAccounts}</div>
                    <div>
                      New Accounts Opened in Past 2 Years:&nbsp;
                      {scoreUpStats?.newAccounts}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </DetailsWrapper>
      </Wrapper>
    </div>
  );
};

ScoreUp.defaultProps = {
  isAIO: false
};

export default observer(ScoreUp);
